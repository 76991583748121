
.timer-alert div:first-child {
 
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin-top: 1em;
    padding: 1em;
    text-align: center;
}

.timer-alert section p:first-child, .timer-alert  div:last-child span {
    font-size: 1em;
}


@media screen and (max-width: 480px) {
    .timer-alert div:first-child {
        padding: 1em;
    }
.timer-alert    section p:first-child, .timer-alert  div:last-child span {
        font-size: 1em;
     /* margin-left: 2em;*/
    /*margin-right: 2em;*/
    /*padding: 3em 1em;  */
    }
    
}